import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Checkbox, Grid, TextField, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from "@material-ui/icons";
import Dialog from "../../../waybee-ui/Dialog";
import WuiConfirmButton from "../../../components/Button/ConfirmButton";
import TicketsService from "../../../services/TicketsService";

const ContentGrid = styled(Grid)`
  padding: 45px 65px 60px 65px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

const ButtonStyle = `
  width: 158px;
  height: 56px;
  margin-left: 32px;
  margin-right: 32px;
`;

const ConfirmButton = styled(WuiConfirmButton)`
  ${ButtonStyle}
`;

const Label = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 15px;
`;

const TitleSection = styled(Grid)`
  margin: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 0 !important;
`;

const GroupDialog = ({
  open,
  onClose,
  onConfirm,
  isEditing,
  onEdit,
  onEditing,
  group,
  onDelete,
  onChangeStatus
}) => {
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors }
  } = useForm({
    defaultValues: {
      name: "",
      type: "",
      users: []
    }
  });
  const [employeeList, setEmployeeList] = useState([]);
  const [users, setUsers] = useState([]);

  const types = [{ id: 1, name: "Funcionários" }];

  const getEmployees = async () => {
    try {
      const employees = await TicketsService.getEmployees();

      setEmployeeList(
        employees.map(employee => {
          return { id: employee.id, name: employee.name };
        })
      );
    } catch (e) {
      alert.show("Erro ao criar listar funcionários", {
        title: "Ocorreu um erro"
      });
    }
  };

  useEffect(() => {
    getEmployees();

    if (open && !_.isEmpty(group)) {
      const { name, usersDetails, type } = group;
      setUsers(usersDetails);
      reset({
        name,
        users: usersDetails,
        type: types.find(t => t.name === type)
      });
    }
  }, [open]);

  const onSubmit = data => {
    onConfirm(data);

    reset({
      name: "",
      type: "",
      users: []
    });
  };

  const onEditForm = async () => {
    const values = getValues();

    const currentUsers = values.users || [];

    const removedUsers = users.filter(
      user => !currentUsers.some(currentUser => currentUser.id === user.id)
    );

    if (removedUsers.length > 0) {
      values.removedUsers = removedUsers;
    }

    const newUsers = currentUsers.filter(
      currentUser => !users.some(user => user.id === currentUser.id)
    );
    if (newUsers.length > 0) {
      values.newUsers = newUsers;
    }

    await onEdit(values, group.id);
    reset({
      name: "",
      type: "",
      users: []
    });
  };

  const handleClose = () => {
    onClose();
    reset({
      name: "",
      type: "",
      users: []
    });
  };

  useEffect(() => {
    if (!isEditing && _.isEmpty(group)) {
      reset({
        name: "",
        type: "",
        users: []
      });
    }
  }, [open]);

  let modalTitle = "";
  if (isEditing) {
    modalTitle = "Editar grupo";
  } else if (!_.isEmpty(group)) {
    modalTitle = "Detalhes do grupo";
  } else {
    modalTitle = "Criar novo grupo";
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      closeButton
      maxWidth="md"
      fullWidth
    >
      <ContentGrid container spacing={2}>
        <TitleSection item xs={12}>
          <Typography variant="h2">{modalTitle}</Typography>
        </TitleSection>
        <Grid item xs={4}>
          {isEditing || _.isEmpty(group) ? (
            <>
              <TextField
                id="name"
                name="name"
                {...register("name", { required: "Campo obrigatório" })}
                error={errors.name}
                variant="outlined"
                fullWidth
                label="Nome do grupo"
                inputProps={{
                  maxLength: "55"
                }}
              />
              {errors.name && errors.name.message}
            </>
          ) : (
            <>
              <Label>Nome*</Label>
              <Typography variant="h5">{group.name}</Typography>
            </>
          )}
        </Grid>
        <Grid item xs={4}>
          {isEditing || _.isEmpty(group) ? (
            <>
              <Controller
                name="type"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    value={value}
                    {...register("type", { required: "Campo Obrigatório" })}
                    id="type"
                    name="type"
                    options={types}
                    getOptionLabel={option => option.name || ""}
                    onChange={(event, newValue) => {
                      onChange(newValue);
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Tipo"
                        fullWidth
                      />
                    )}
                    disableCloseOnSelect
                    renderOption={option => (
                      <React.Fragment>{option.name}</React.Fragment>
                    )}
                  />
                )}
              />
              {errors.type && errors.type.message}
            </>
          ) : (
            <>
              <Label>Tipo*</Label>
              <Typography variant="h5">{group.type}</Typography>
            </>
          )}
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="users"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Autocomplete
                multiple
                value={Array.isArray(value) ? value : []}
                {...register("users")}
                id="users"
                name="users"
                options={employeeList}
                getOptionLabel={option => option.name || ""}
                getOptionSelected={(option, valueSelected) =>
                  valueSelected.id === option.id
                }
                disabled={!isEditing && !_.isEmpty(group)}
                onChange={(event, newValue) => onChange(newValue)}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Usuários"
                    fullWidth
                  />
                )}
                disableCloseOnSelect
                renderOption={(option, { selected }) => (
                  <>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlankIcon />}
                      checkedIcon={<CheckBoxIcon />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.name}
                  </>
                )}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Actions>
            {!isEditing && !_.isEmpty(group) && (
              <ConfirmButton
                onClick={() => onChangeStatus(group, group.active)}
                text={`${group.active ? "Desativar" : "Ativar"}`}
              />
            )}
            {isEditing || _.isEmpty(group) ? (
              <ConfirmButton
                onClick={
                  isEditing ? handleSubmit(onEditForm) : handleSubmit(onSubmit)
                }
                text="Salvar"
              />
            ) : (
              <>
                <ConfirmButton onClick={() => onEditing(true)} text="Editar" />
                <ConfirmButton
                  color="#DC3545"
                  onClick={() => onDelete(group)}
                  text="Excluir"
                />
              </>
            )}
          </Actions>
        </Grid>
      </ContentGrid>
    </Dialog>
  );
};

GroupDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  group: PropTypes.objectOf(PropTypes.shape()).isRequired,
  onEditing: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onChangeStatus: PropTypes.func.isRequired
};

export default GroupDialog;
