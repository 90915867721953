import { api } from "../utils/api";

const getAcl = async () => {
  try {
    const response = await api.get("WaybeeUsers/getAcl");
    return response;
  } catch (e) {
    console.error(e);
  }

  return [];
};

const getRoles = async () => {
  const res = await api.get("AclWaybee/role");
  return res.data;
};

const getUserRoles = async userId => {
  const res = await api.get(`AclWaybee/${userId}/role`);
  return res.data;
};

const getAppRoles = async () => {
  const res = await api.get("AclWaybee/app-roles");
  return res.data;
};

const getPermissions = async () => {
  const res = await api.get("AclWaybee/permission");
  return res.data;
};

const getRolePermissions = async id => {
  const res = await api.get(`AclWaybee/${id}/rolePermissions`);
  return res.data;
};

const getRoleUsers = async id => {
  const res = await api.get(`AclWaybee/${id}/roleUsers`);
  return res.data;
};

const saveRole = async data => {
  const res = await api.post("AclWaybee/role", data);
  return res.data;
};

const getCommUserRoles = async () => {
  const res = await api.get("AclWaybee/role/commUser");
  return res.data;
};

const updateCommUserRoles = async values => {
  const res = await api.post("AclWaybee/role/commUser", values);
  return res.data;
};

const deleteRole = async roleId => {
  const res = await api.put(`AclWaybee/role/${roleId}/delete`);

  return res.data;
};

const updateRole = async data => {
  const res = await api.post("AclWaybee/role/update", data);
  return res.data;
};

const AclService = {
  getAcl,
  getRoles,
  getUserRoles,
  getPermissions,
  saveRole,
  getCommUserRoles,
  updateCommUserRoles,
  getAppRoles,
  deleteRole,
  updateRole,
  getRolePermissions,
  getRoleUsers
};

export default AclService;
