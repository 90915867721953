import React from "react";
import PropTypes from "prop-types";
import ReactDataGrid from "@inovua/reactdatagrid-community";
import { IconButton } from "@material-ui/core";
import {
  MoreHoriz as ActionsIcon,
  BorderColor,
  Delete,
  LockOutlined
} from "@material-ui/icons";
import styled from "styled-components";
import ClickPopover from "../../../components/ClickPopover";
import HoverPopover from "../../../components/HoverPopover";

const DataGrid = styled(ReactDataGrid)`
  &.InovuaReactDataGrid {
    border: none;
    font-family: Nunito, sans-serif;
    font-size: 14px;
    min-height: 550px;
    background: #f5f5f5;
    cursor: pointer;
  }

  .InovuaReactDataGrid__header,
  .InovuaReactDataGrid__row {
    background: #f5f5f5;
  }

  .InovuaReactDataGrid__column-header {
    border: none;
  }
`;

const LockIcon = styled(LockOutlined)`
  width: 24px;
  height: 24px;
`;

const LockContainer = styled.div`
  display: inline-block;
  width: 26px;
  height: 26px;
`;

const AclRoleList = ({ roles, loading, onDelete, onOpenDialog }) => {
  const actions = [
    {
      title: "Editar perfil",
      onClick: data => onOpenDialog(data, true),
      icon: <BorderColor />
    },
    {
      title: "Excluir perfil",
      onClick: data => onDelete(data),
      icon: <Delete />
    }
  ];

  const columns = [
    {
      name: "name",
      header: "Nome",
      defaultFlex: 1
    },
    {
      name: "description",
      header: "Descrição",
      defaultFlex: 1
    },
    {
      name: "actions",
      header: "Ações",
      headerAlign: "center",
      textAlign: "center",
      onClick: e => {
        // setRole(row.data);
        e.stopPropagation();
      },
      render: ({ data }) => {
        return data.editable ? (
          <IconButton>
            <ClickPopover value="Usuário externo" items={actions} data={data}>
              <ActionsIcon />
            </ClickPopover>
          </IconButton>
        ) : (
          <LockContainer>
            <HoverPopover id={data.id} value="Perfil não editável">
              <LockIcon />
            </HoverPopover>
          </LockContainer>
        );
      }
    }
  ];

  const i18n = Object.assign({}, ReactDataGrid.defaultProps.i18n, {
    pageText: "Página",
    ofText: "de",
    perPageText: "Itens por página",
    showingText: "Mostrando",
    recordsText: "registros"
  });

  return (
    <DataGrid
      idProperty="id"
      showCellBorders={false}
      showZebraRows={false}
      columns={columns}
      dataSource={roles}
      showColumnMenuTool={false}
      resizable={false}
      reorderColumns={false}
      cellSelectionByIndex={false}
      rowHeight={50}
      sortable={false}
      loading={loading}
      enableSelection
      onSelectionChange={row => onOpenDialog(row.data, false)}
      pagination
      limit={20}
      i18n={i18n}
    />
  );
};

AclRoleList.defaultProps = {
  loading: false
};

AclRoleList.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onOpenDialog: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

export default AclRoleList;
