import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Grid, TextField, Checkbox, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon
} from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import _ from "lodash";
import Dialog from "../../../waybee-ui/Dialog";
import WuiConfirmButton from "../../../components/Button/ConfirmButton";
import WuiCancelButton from "../../../components/Button/CancelButton";
import AclService from "../../../services/AclService";

const ContentGrid = styled(Grid)`
  padding: 45px 65px 60px 65px;
`;

const Actions = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;

const ButtonStyle = `
  width: 158px;
  height: 56px;
  margin-left: 32px;
  margin-right: 32px;
`;

const ConfirmButton = styled(WuiConfirmButton)`
  ${ButtonStyle}
`;

const CancelButton = styled(WuiCancelButton)`
  ${ButtonStyle}
`;

const TitleSection = styled(Grid)`
  margin: 12px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  padding-bottom: 0 !important;
`;

const PermissionField = styled(Autocomplete)`
  max-height: 120px;
  overflow: auto;
`;

const Label = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 4px;
  margin-top: 15px;
`;

const AclDialog = ({
  open,
  onClose,
  permissionsList,
  employees,
  onConfirm,
  isEditing,
  onEdit,
  onEditing,
  role,
  onDelete
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    reset,
    control
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      permissions: [],
      users: []
    }
  });

  const [permissions, setPermissions] = useState([]);
  const [users, setUsers] = useState([]);

  const getRolePermissions = async () => {
    const rolePermissions = await AclService.getRolePermissions(role.id);
    setPermissions(rolePermissions);
  };

  const getRoleUsers = async () => {
    const rolePermissions = await AclService.getRoleUsers(role.id);
    setUsers(rolePermissions);
  };

  useEffect(() => {
    if (open && !_.isEmpty(role)) {
      getRolePermissions();
      getRoleUsers();
    }
  }, [open]);

  useEffect(() => {
    if (open && !_.isEmpty(role)) {
      reset({
        ...role,
        permissions,
        users
      });
    }
  }, [open, permissions, users]);

  const onSubmit = data => {
    onConfirm(data);
    onClose();
  };

  const onEditForm = async () => {
    const values = getValues();

    const currentUsers = values.users || [];
    const currentPermissions = values.permissions || [];

    const removedUsers = users.filter(
      user => !currentUsers.some(currentUser => currentUser.id === user.id)
    );

    if (removedUsers.length > 0) {
      values.removedUsers = removedUsers;
    }

    const removedPermissions = permissions.filter(
      permission =>
        !currentPermissions.some(
          cPermission => cPermission.id === permission.id
        )
    );
    if (removedPermissions.length > 0) {
      values.removedPermissions = removedPermissions;
    }

    const newUsers = currentUsers.filter(
      currentUser => !users.some(user => user.id === currentUser.id)
    );
    if (newUsers.length > 0) {
      values.newUsers = newUsers;
    }

    const newPermissions = currentPermissions.filter(
      currentPermission =>
        !permissions.some(permission => permission.id === currentPermission.id)
    );
    if (newPermissions.length > 0) {
      values.newPermissions = newPermissions;
    }

    await onEdit(values);
  };

  const handleNameChange = value => {
    return value
      .toLowerCase()
      .replace(/[^a-z0-9\s-]/g, "")
      .replace(/\s+/g, "-")
      .replace(/--+/g, "-");
  };

  useEffect(() => {
    if (!isEditing) {
      reset({
        name: "",
        description: "",
        permissions: [],
        users: []
      });
    }
  }, [open]);

  return (
    <Dialog
      scroll="body"
      open={open}
      onClose={onClose}
      closeButton
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ContentGrid container spacing={2}>
          <TitleSection item xs={12}>
            <Typography variant="h2">
              {!isEditing && _.isEmpty(role)
                ? "Criar novo perfil"
                : "Perfil de acesso"}
            </Typography>
          </TitleSection>
          <Grid item xs={4}>
            {!isEditing && !_.isEmpty(role) ? (
              <>
                <Label>Nome</Label>
                <Typography variant="h5">{role.name}</Typography>
              </>
            ) : (
              <TextField
                id="name"
                name="name"
                {...register("name")}
                variant="outlined"
                fullWidth
                onChange={e =>
                  setValue("name", handleNameChange(e.target.value))
                }
                label="Nome do perfil"
                inputProps={{
                  maxLength: "15"
                }}
              />
            )}
            {errors.name && <span>{errors.name.message}</span>}
          </Grid>

          <Grid item xs={8}>
            {!isEditing && !_.isEmpty(role) ? (
              <>
                <Label>Descrição</Label>
                <Typography variant="h5">{role.description}</Typography>
              </>
            ) : (
              <TextField
                id="description"
                name="description"
                {...register("description")}
                variant="outlined"
                fullWidth
                label="Descrição do perfil"
                inputProps={{
                  maxLength: "55"
                }}
              />
            )}
            {errors.description && <span>{errors.description.message}</span>}
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="permissions"
              control={control}
              render={({ field: { onChange, value } }) =>
                !isEditing && !_.isEmpty(role) ? (
                  <>
                    <Label>Permissões</Label>
                    {value.map(permission => (
                      <Typography variant="body1">
                        {permission.description}
                      </Typography>
                    ))}
                  </>
                ) : (
                  <PermissionField
                    multiple
                    id="permissions"
                    value={value}
                    disabled={!isEditing && !_.isEmpty(role)}
                    options={permissionsList}
                    getOptionLabel={option => option.description || ""}
                    getOptionSelected={(option, valueSelected) =>
                      option.id === valueSelected.id
                    }
                    onChange={(event, newValue) => onChange(newValue)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        margin="none"
                        label="Permissões"
                        fullWidth
                        variant="outlined"
                        error={!!errors.permissions}
                        helperText={errors.permissions?.message}
                      />
                    )}
                    disableCloseOnSelect
                    renderOption={(option, { selected }) => (
                      <>
                        <Checkbox
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.description || ""}
                      </>
                    )}
                  />
                )
              }
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name="users"
              control={control}
              render={({ field: { onChange, value } }) =>
                !isEditing && !_.isEmpty(role) ? (
                  <>
                    <Label>Usuários</Label>
                    <Typography variant="body1">
                      {value.map(user => user.name).join(", ")}
                    </Typography>
                  </>
                ) : (
                  <Autocomplete
                    multiple
                    disabled={!isEditing && !_.isEmpty(role)}
                    value={value}
                    {...register("users")}
                    id="users"
                    name="users"
                    options={employees}
                    getOptionLabel={option => option.name || ""}
                    getOptionSelected={(option, valueSelected) =>
                      option.id === valueSelected.id
                    }
                    onChange={(event, newValue) => onChange(newValue)}
                    renderInput={params => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Usuários"
                        fullWidth
                      />
                    )}
                    disableCloseOnSelect
                    renderOption={(option, { selected }) => (
                      <>
                        <Checkbox
                          color="primary"
                          icon={<CheckBoxOutlineBlankIcon />}
                          checkedIcon={<CheckBoxIcon />}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </>
                    )}
                  />
                )
              }
            />
            {errors.users && <span>{errors.users.message}</span>}
          </Grid>

          <Grid item xs={12}>
            <Actions>
              <CancelButton onClick={onClose} text="Cancelar" />
              {!isEditing && !_.isEmpty(role) && (
                <ConfirmButton onClick={() => onEditing(true)} text="Editar" />
              )}
              {!isEditing && !_.isEmpty(role) && (
                <ConfirmButton
                  color="#DC3545"
                  onClick={() => onDelete(role)}
                  text="Excluir"
                />
              )}
              {(isEditing || _.isEmpty(role)) && (
                <ConfirmButton
                  onClick={handleSubmit(isEditing ? onEditForm : onConfirm)}
                  text="Salvar"
                />
              )}
            </Actions>
          </Grid>
        </ContentGrid>
      </form>
    </Dialog>
  );
};

AclDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  permissionsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  employees: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired
    })
  ).isRequired,
  isEditing: PropTypes.bool.isRequired,
  onEdit: PropTypes.func.isRequired,
  onEditing: PropTypes.func.isRequired,
  role: PropTypes.objectOf(PropTypes.shape()).isRequired,
  onDelete: PropTypes.func.isRequired
};

export default AclDialog;
