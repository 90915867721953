import React, { Component } from "react";
import PropTypes from "prop-types";
import { Box, Grid, TextField } from "@material-ui/core";
import _ from "lodash";
import styled from "styled-components";
import { DebounceInput } from "react-debounce-input";
import { Add } from "@material-ui/icons";
import { withAlert } from "react-alert";
import AclService from "../../services/AclService";
import AclRoleList from "./components/AclRoleList";
import Button from "../../components/Button/Button";
import BackButton from "../../components/BackButton";
import TicketsService from "../../services/TicketsService";
import AclDialog from "./components/AclDialog";
import SimpleConfirmDialog from "../../waybee-ui/Dialog/SimpleConfirmDialog";

const FilterContainer = styled(Grid)`
  margin-bottom: 12px;

  .MuiBox-root {
    margin-right: 16px;
  }
  .MuiInputBase-input {
    padding-top: 11px;
    padding-bottom: 11px;
  }
`;

const FilterField = styled(TextField)`
  margin-right: 16px;
`;

class AclList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roles: [],
      filters: {
        name: "",
        description: ""
      },
      filteredRoles: [],
      permissionsList: [],
      employees: [],
      isAclDialogOpen: false,
      confirmDialog: {},
      isEditing: false,
      role: {}
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Perfis de Acesso");

    this.getRoles();
    this.getPermissions();
    this.getEmployees();
  }

  getRoles = async () => {
    const roles = await AclService.getRoles();
    this.setState({ roles, filteredRoles: roles });
  };

  getFilteredRoles = async (field, search) => {
    const { filters, roles } = this.state;
    filters[field] = search;

    this.setState({ filters }, () => {
      const newRoleList = _.filter(roles, role => {
        return _.every(filters, (value, key) => {
          if (value === "") return true;

          if (["name", "description"].includes(key)) {
            return (
              typeof role[key] === "string" &&
              role[key].toLowerCase().includes(value.toLowerCase())
            );
          }

          return role[key] === value;
        });
      });

      this.setState({ filteredRoles: newRoleList });
    });
  };

  getPermissions = async () => {
    const permissionsList = await AclService.getPermissions();
    this.setState({ permissionsList });
  };

  getEmployees = async () => {
    const { alert } = this.props;
    try {
      const employees = await TicketsService.getEmployees();

      if (employees.length) {
        const employeesList = employees.map(employee => ({
          id: employee.id,
          name: employee.name
        }));
        this.setState({ employees: employeesList });
      }
    } catch (e) {
      alert.show("Erro ao listar funcionários", {
        title: "Ocorreu um erro"
      });
    }
  };

  handleOpenDialog = () => {
    this.setState({ isAclDialogOpen: true });
  };

  handleCloseDialog = () => {
    this.setState({ isAclDialogOpen: false, role: {}, isEditing: false });
  };

  saveRole = async values => {
    const { alert } = this.props;

    try {
      await AclService.saveRole(values);
      await this.getRoles();
      this.handleCloseDialog();
      alert.show("Perfil salvo com sucesso!", {
        title: "Sucesso",
        type: "success"
      });
    } catch (error) {
      alert.show("Erro ao salvar o perfil", {
        title: "Ocorreu um erro"
      });
    }
  };

  onDeleteRole = async role => {
    this.setState({
      confirmDialog: {
        open: true,
        title: `Deletar perfil de acesso`,
        message: `Ao confirmar, o perfil de acesso "${role.description}" será deletado. Deseja continuar?`,
        onConfirm: async () => this.onConfirmDeleteRole(role.id),
        onClose: () => {
          this.setState({ confirmDialog: {} });
        }
      },
      role: {},
      isEditing: false
    });
  };

  onOpenRole = (data, isEditing) => {
    this.setState({
      role: data,
      isAclDialogOpen: true,
      isEditing
    });
  };

  onConfirmDeleteRole = async id => {
    const { alert } = this.props;

    try {
      await AclService.deleteRole(id);

      this.setState({ isAclDialogOpen: false });
      alert.show("Perfil de acesso deletado com sucesso!", {
        title: "Sucesso",
        onClose: () => {
          this.getRoles();
          this.setState({
            confirmDialog: {}
          });
        }
      });
    } catch (e) {
      console.error(e);
      alert.show(
        `
      Ocorreu um erro no processo de exclusão do perfil de acesso
      ${e.message}
      `,
        {
          title: "Erro"
        }
      );
    }
  };

  onConfirmRoleUpdate = async data => {
    const { alert } = this.props;

    try {
      await AclService.updateRole(data);

      this.setState({ isAclDialogOpen: false });

      alert.show(`Perfil de acesso editado com sucesso!`, {
        title: "Sucesso",
        onClose: () => {
          this.getRoles();
          this.setState({
            isEditing: false,
            confirmDialog: {},
            role: {}
          });
        }
      });
    } catch (e) {
      console.error(e);
      alert.show(
        `
      Ocorreu um erro no processo de edição do peprfil de acesso
      ${e.message}
      `,
        {
          title: "Erro"
        }
      );
    }
  };

  onChangeEditing = isEditing => {
    this.setState({ isEditing });
  };

  render() {
    const {
      filters,
      filteredRoles,
      permissionsList,
      employees,
      isAclDialogOpen,
      confirmDialog,
      role,
      isEditing
    } = this.state;

    const openConfirmDialog = Object.keys(confirmDialog).length !== 0;

    return (
      <>
        <BackButton to="/users" />
        <FilterContainer container>
          <Box width={165} flexShrink={0}>
            <Button
              color="#4CAF50"
              onClick={this.handleOpenDialog}
              icon={<Add />}
              textColor="white"
            >
              <Box pl={0.8}>Criar perfil</Box>
            </Button>
          </Box>
          <DebounceInput
            debounceTimeout={400}
            element={FilterField}
            name="name"
            placeholder="Nome"
            variant="outlined"
            value={filters.name}
            onChange={e => this.getFilteredRoles(e.target.name, e.target.value)}
          />
          <DebounceInput
            debounceTimeout={400}
            element={FilterField}
            name="description"
            placeholder="Descrição"
            variant="outlined"
            value={filters.description}
            onChange={e => this.getFilteredRoles(e.target.name, e.target.value)}
          />
        </FilterContainer>
        <AclRoleList
          roles={filteredRoles}
          onDelete={this.onDeleteRole}
          onOpenDialog={this.onOpenRole}
        />
        <AclDialog
          open={isAclDialogOpen}
          onClose={this.handleCloseDialog}
          onConfirm={this.saveRole}
          isEditing={isEditing}
          role={role}
          onEdit={this.onConfirmRoleUpdate}
          onEditing={this.onChangeEditing}
          permissionsList={permissionsList}
          employees={employees}
          onDelete={this.onDeleteRole}
        />

        {openConfirmDialog && (
          <SimpleConfirmDialog
            open={confirmDialog.open}
            title={confirmDialog.title}
            message={confirmDialog.message}
            onConfirm={confirmDialog.onConfirm}
            onClose={confirmDialog.onClose}
            isEditing={isEditing}
          />
        )}
      </>
    );
  }
}

AclList.propTypes = {
  setTitle: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired
};

export default withAlert()(AclList);
