import React, { Component } from "react";
import PropTypes from "prop-types";
import { withAlert } from "react-alert";
import AclService from "../../services/AclService";
import AclDevForm from "./components/AclDevForm";
import Button from "../../waybee-ui/Button";

class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // roles: [],
      permissions: [],
      values: {}
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Configuração de Acessos");

    // this.getRoles();
    this.getPermissions();
  }

  // getRoles = async () => {
  //   const roles = await AclService.getRoles();
  //   this.setState({roles});
  // }

  getPermissions = async () => {
    const permissions = await AclService.getPermissions();
    this.setState({ permissions });
  };

  changeValue = newValues => {
    const { values } = this.state;
    this.setState({
      values: {
        ...values,
        ...newValues
      }
    });
  };

  saveRole = async () => {
    const { values } = this.state;
    await AclService.saveRole(values);
  };

  render() {
    const { permissions } = this.state;

    return (
      <div>
        <AclDevForm permissions={permissions} onChange={this.changeValue} />

        <Button color="primary" variant="contained" onClick={this.saveRole}>
          Salvar
        </Button>
      </div>
    );
  }
}

Chat.propTypes = {
  setTitle: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired
};

export default withAlert()(Chat);
