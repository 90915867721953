import { api } from "../utils/api";

const getGroups = async filters => {
  const res = await api.get(`Groups/groups`, {
    params: {
      filter: {
        name: filters.name,
        status: filters.status,
        userName: filters.userName
      }
    }
  });
  return res.data;
};

const updateGroup = async (groupId, data) => {
  const res = await api.post(`Groups/group/${groupId}`, data);
  return res.data;
};

const deleteGroup = async groupId => {
  const res = await api.delete(`Groups/${groupId}`);
  return res.data;
};

const createGroup = async data => {
  const res = await api.post(`Groups/groups`, data);
  return res.data;
};

export default {
  getGroups,
  updateGroup,
  deleteGroup,
  createGroup
};
