import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import {
  Box,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Select,
  TextField
} from "@material-ui/core";
import styled from "styled-components";
import { DebounceInput } from "react-debounce-input";
import { withAlert } from "react-alert";
import { Add, FilterListRounded } from "@material-ui/icons";
import GroupService from "../../services/GroupService";
import GroupList from "./components/GroupList";
import SimpleConfirmDialog from "../../waybee-ui/Dialog/SimpleConfirmDialog";
import GroupDialog from "./components/GroupDialog";
import Button from "../../components/Button/Button";
import BackButton from "../../components/BackButton";

const FilterContainer = styled(Grid)`
  margin-bottom: 12px;

  .MuiBox-root {
    margin-right: 16px;
  }
  .MuiInputBase-input {
    padding-top: 11px;
    padding-bottom: 11px;
  }
`;

const FilterField = styled(TextField)`
  margin-right: 16px;
`;

const FilterButton = styled(IconButton)`
  border: 1px solid;
  border-radius: 8px;
  padding: 7px;
  margin-left: 16px;
`;

const FilterGrid = styled(Grid)`
  margin-top: 12px;
`;

const SelectControl = styled(FormControl)`
  margin-right: 16px;
`;

class Groups extends Component {
  constructor(props) {
    super(props);
    this.state = {
      groups: [],
      group: {},
      filters: {
        name: null,
        status: "",
        userName: ""
      },
      confirmDialog: {},
      isEditing: false,
      openDialog: false,
      showFilters: ""
    };
  }

  componentDidMount() {
    const { setTitle } = this.props;
    setTitle("Gerenciar Grupos");
    this.getGroups();
  }

  onOpenGroup = (data, isEditing) => {
    this.setState({
      group: data,
      openDialog: true,
      isEditing
    });
  };

  getGroups = async () => {
    const { filters } = this.state;

    const groups = await GroupService.getGroups(filters);
    this.setState({ groups });
  };

  onUpdateGroup = async (value, status) => {
    if (!status || status) {
      const isStatusActive = status;

      this.setState({
        confirmDialog: {
          open: true,
          title: `${isStatusActive ? "Desativar" : "Ativar"} grupo`,
          message: `Ao confirmar, o grupo ${value.name} será ${
            isStatusActive ? "desativado" : "ativado"
          }. Deseja continuar?`,
          onConfirm: async () => this.onChangeStatus(value, status),
          onClose: () => {
            this.setState({ confirmDialog: {} });
          }
        }
      });
    } else {
      await this.onConfirmGroupUpdate(value);
    }
  };

  onChangeStatus = async (group, status) => {
    const { alert } = this.props;

    const groupValue = group;
    groupValue.active = !status;

    try {
      await GroupService.updateGroup(group.id, groupValue);
      alert.show(`Grupo ${status ? "desativado" : "ativado"} com sucesso!`, {
        title: "Sucesso",
        onClose: () => {
          this.getGroups();
          this.setState({
            isEditing: false,
            confirmDialog: {},
            openDialog: false,
            group: {}
          });
        }
      });
    } catch (e) {
      console.error(e);
      alert.show(
        `
      Ocorreu um erro no processo de edição do grupo

      ${e.message}
      `,
        {
          title: "Erro"
        }
      );
    }
  };

  onConfirmGroupUpdate = async (value, groupId) => {
    const { alert } = this.props;

    try {
      await GroupService.updateGroup(groupId, value);
      alert.show("Grupo editado com sucesso!", {
        title: "Sucesso",
        onClose: () => {
          this.getGroups();
          this.setState({
            isEditing: false,
            confirmDialog: {},
            openDialog: false,
            group: {}
          });
        }
      });
    } catch (e) {
      console.error(e);
      alert.show(
        `
      Ocorreu um erro no processo de edição do grupo

      ${e.message}
      `,
        {
          title: "Erro"
        }
      );
    }
  };

  onDeleteGroup = async group => {
    this.setState({
      confirmDialog: {
        open: true,
        title: `Deletar grupo`,
        message: `Ao confirmar, o grupo ${group.name} será deletado. Deseja continuar?`,
        onConfirm: async () => this.onConfirmDeleteGroup(group.id),
        onClose: () => {
          this.setState({ confirmDialog: {} });
        }
      }
    });
  };

  onConfirmDeleteGroup = async id => {
    const { alert } = this.props;

    try {
      await GroupService.deleteGroup(id);

      this.setState({ openDialog: false });
      alert.show("Grupo deletado com sucesso!", {
        title: "Sucesso",
        onClose: () => {
          this.getGroups();
          this.setState({
            isEditing: false,
            confirmDialog: {}
          });
        }
      });
    } catch (e) {
      console.error(e);
      alert.show(
        `
      Ocorreu um erro no processo de exclusão do grupo
      ${e.message}
      `,
        {
          title: "Erro"
        }
      );
    }
  };

  saveGroup = async values => {
    const { alert } = this.props;

    try {
      await GroupService.createGroup(values);
      await this.getGroups();
      this.handleCloseDialog();
      alert.show("Grupo salvo com sucesso!", {
        title: "Sucesso",
        type: "success"
      });
    } catch (error) {
      alert.show("Erro ao salvar o grupo", {
        title: "Ocorreu um erro"
      });
    }
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false, group: {} });
  };

  handleFilterChange = (name, value) => {
    this.setState(
      prevState => ({
        filters: { ...prevState.filters, [name]: value }
      }),
      this.getGroups
    );
  };

  onEditingChange = isEditing => {
    this.setState({ isEditing });
  };

  clearFilter = () => {
    this.setState(
      {
        filters: {
          name: null,
          status: "",
          userName: ""
        }
      },
      this.getGroups
    );
  };

  render() {
    const {
      filters,
      groups,
      confirmDialog,
      isEditing,
      group,
      openDialog,
      showFilters
    } = this.state;

    const openConfirmDialog = Object.keys(confirmDialog).length !== 0;
    return (
      <>
        <BackButton to="/users" />
        <FilterContainer container>
          <Box width={165} flexShrink={0}>
            <Button
              color="#4CAF50"
              onClick={() =>
                this.setState({ openDialog: true, isEditing: false })
              }
              icon={<Add />}
              textColor="white"
            >
              <Box pl={0.8}>Criar grupo</Box>
            </Button>
          </Box>
          <FilterButton
            onClick={() => {
              this.setState({ showFilters: !showFilters });
            }}
          >
            <FilterListRounded />
          </FilterButton>
          {showFilters && (
            <FilterGrid container item xs={12}>
              <DebounceInput
                debounceTimeout={400}
                element={FilterField}
                name="name"
                placeholder="Nome do Grupo"
                variant="outlined"
                value={filters.name}
                onChange={e =>
                  this.handleFilterChange(e.target.name, e.target.value)
                }
              />
              <DebounceInput
                debounceTimeout={400}
                element={FilterField}
                name="userName"
                placeholder="Nome do Usuário"
                variant="outlined"
                value={filters.userName}
                onChange={e =>
                  this.handleFilterChange(e.target.name, e.target.value)
                }
              />
              <SelectControl variant="outlined">
                <Select
                  name="status"
                  value={filters.status}
                  displayEmpty
                  onChange={e =>
                    this.handleFilterChange(e.target.name, e.target.value)
                  }
                >
                  <MenuItem value="">Status</MenuItem>
                  <MenuItem value={1}>Ativo</MenuItem>
                  <MenuItem value={0}>Inativo</MenuItem>
                </Select>
              </SelectControl>
              <Box width={165} flexShrink={0}>
                <Button onClick={() => this.clearFilter()} textColor="black">
                  Redefinir filtro
                </Button>
              </Box>
            </FilterGrid>
          )}
        </FilterContainer>
        {openConfirmDialog && (
          <SimpleConfirmDialog
            open={confirmDialog.open}
            title={confirmDialog.title}
            message={confirmDialog.message}
            onConfirm={confirmDialog.onConfirm}
            onClose={confirmDialog.onClose}
          />
        )}
        <GroupList
          groups={groups}
          onOpenDialog={this.onOpenGroup}
          onDelete={this.onDeleteGroup}
          onChangeStatus={this.onUpdateGroup}
        />
        <GroupDialog
          open={openDialog}
          onClose={this.handleCloseDialog}
          onConfirm={this.saveGroup}
          isEditing={isEditing}
          group={group}
          onEdit={this.onConfirmGroupUpdate}
          onEditing={this.onEditingChange}
          onDelete={this.onDeleteGroup}
          onChangeStatus={this.onUpdateGroup}
        />
      </>
    );
  }
}

Groups.propTypes = {
  setTitle: PropTypes.func.isRequired,
  alert: PropTypes.shape({
    show: PropTypes.func
  }).isRequired // injected by withAlert
};

export default withAlert()(Groups);
