import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Box, Drawer } from "@material-ui/core";
import Loading from "../../../components/Loading";
import TicketDetailHeader from "./TicketDetailHeader";
import TicketDetailDescription from "./TicketDetailDescription";
import TicketDetailAttachments from "./TicketDetailAttachments";
import TicketDetailHistory from "./TicketDetailHistory";
import TicketDetailComments from "./TicketDetailComments";

const Content = styled.div`
  padding: 60px 40px 20px 40px;
  width: 636px;

  @media (max-width: 784px) {
    width: 100%;
    padding: 30px 20px 10px 20px;
  }
`;

const TicketDetail = ({
  open,
  onClose,
  ticket,
  status,
  employees,
  selectedChannel,
  setAttendee,
  changeStatus,
  channels,
  changeTicketChannel,
  disableActions
}) => {
  const detailDrawerRef = useRef(null);
  const userId = parseInt(localStorage.getItem("userId"), 10);
  const [isAttendee, setIsAttendee] = useState(
    ticket && ticket.attendee && ticket.attendee.id === userId
  );

  const members = ticket
    ? [ticket.attendee, ticket.createdBy, ...employees]
    : [];

  useEffect(() => {
    setIsAttendee(ticket && ticket.attendee && ticket.attendee.id === userId);
  }, [userId, ticket]);

  return (
    <Drawer open={open} onClose={onClose} anchor="right">
      {!ticket ? (
        <Loading />
      ) : (
        <Content ref={detailDrawerRef}>
          <TicketDetailHeader
            onClose={onClose}
            ticket={ticket}
            status={status}
            employees={employees}
            channels={channels}
            currentChannel={selectedChannel}
            setAttendee={user => {
              setAttendee(ticket.id, user);
              setIsAttendee(userId === user.id);
            }}
            changeTicketChannel={changeTicketChannel}
            changeStatus={(oldStatus, newStatus) =>
              changeStatus(
                ticket.id,
                { fromColumnId: oldStatus },
                { toColumnId: newStatus }
              )
            }
            disableActions={disableActions}
          />
          <Box mt={1}>
            <TicketDetailDescription description={ticket.description} />
            {ticket.ticketAttachments.length !== 0 && (
              <TicketDetailAttachments attachments={ticket.ticketAttachments} />
            )}
            <TicketDetailHistory logs={ticket.ticketLogs} />
            <TicketDetailComments
              ticketId={ticket.id}
              roomId={ticket.roomId}
              members={members}
              disableActions={disableActions || !isAttendee}
              ticketChannelId={ticket.channel.id}
              detailDrawerRef={detailDrawerRef}
            />
          </Box>
        </Content>
      )}
    </Drawer>
  );
};

TicketDetail.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  ticket: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    ticketAttachments: PropTypes.arrayOf(),
    ticketLogs: PropTypes.arrayOf(),
    roomId: PropTypes.string,
    attendee: PropTypes.shape(),
    createdBy: PropTypes.shape,
    channel: PropTypes.shape({
      id: PropTypes.number
    })
  }).isRequired,
  status: PropTypes.arrayOf().isRequired,
  employees: PropTypes.arrayOf().isRequired,
  setAttendee: PropTypes.func.isRequired,
  changeStatus: PropTypes.func.isRequired,
  disableActions: PropTypes.bool.isRequired,
  channels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedChannel: PropTypes.objectOf().isRequired,
  changeTicketChannel: PropTypes.func.isRequired
};

export default TicketDetail;
